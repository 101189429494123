(function($) {

	var theme = {

		defaults: {
			mobileMenu: {
				trigger: $('.primary-navigation .mobile-menu-toggle'),
				searchTrigger: $('.primary-navigation .mobile-search-toggle'),
				childrenTrigger: $('.primary-navigation .menu-item-has-children')
			},
			homeSlider: {
				container: $('.front-page-slider')
			}
		},
		init: function()
		{
			theme.mobileMenu();
			theme.homeSliderInit();
		},
		mobileMenu: function()
		{
			var mobileMenu = theme.defaults.mobileMenu;
			mobileMenu.trigger.on( 'click', function () {
				var menu = $(this).parent( '.primary-navigation' );
				menu.toggleClass( 'open-nav' );
			});
			mobileMenu.searchTrigger.on( 'click', function () {
				var menu = $(this).parent( '.primary-navigation' );
				menu.toggleClass( 'open-search' );
			});
			mobileMenu.childrenTrigger.on('click', function() {
				if( $(this).hasClass('open-submenu') ) {
					return true;
				}
				$(this).toggleClass('open-submenu');
				return false;
			});
		},
		homeSliderInit: function()
		{
			var homeSlider = theme.defaults.homeSlider;
			homeSlider.container.slick({
				dots: true,
				arrows: false
			});
		}
	};

	theme.init();

}(jQuery));